<script setup lang="ts">
import { SwiperSlide, Swiper } from 'swiper/vue'
import 'swiper/css'
import type { CarouselMobileCard } from '~/types/benchmark-app.type'

defineProps({
  phoneCards: {
    type: Array as PropType<CarouselMobileCard[]>,
    required: true,
  },
  imageClass: {
    type: String,
    default: 'h-[460px] w-[227px]',
  },
})

const isLessThan600px = ref(false)
const initializeBreakpoints = () => {
  const mqlMobile = window?.matchMedia('(max-width: 600px)')

  isLessThan600px.value = mqlMobile.matches

  mqlMobile.onchange = (e) => {
    isLessThan600px.value = e.matches
  }
}

const loadingSwiper = ref(true)
onMounted(() => {
  loadingSwiper.value = false
  initializeBreakpoints()
})
</script>

<template>
  <div
    class="relative mb-11 w-full overflow-hidden lg:hidden"
    :class="{
      'pl-6': isLessThan600px,
    }"
  >
    <Swiper
      class="w-[544px]"
      :class="loadingSwiper ? 'opacity-0' : 'opacity-100'"
      :slides-per-view="2"
      :slides-offset-after="isLessThan600px ? 220 : 0"
      :space-between="16"
      :speed="500"
    >
      <SwiperSlide v-for="(card, i) in phoneCards" :key="i" class="w-[264px]">
        <div
          class="py-5 rounded-xl w-[264px]"
          :class="i % 2 === 0 ? 'bg-s-900' : 'bg-p-100'"
        >
          <h2
            class="mb-5 min-h-[58px] text-h3 pl-5 pr-1"
            :class="i % 2 === 0 ? 'text-white' : ''"
          >
            {{ card.title }}
          </h2>
          <div class="px-5">
            <img
              :class="imageClass"
              :src="card.src"
              :alt="card.title + ' Banner'"
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
