<script setup lang="ts">
import type { CarouselMobileCard } from '@/types/benchmark-app.type'
import RadioButtonCheckedIcon from '@/assets/icons/radio-button-checked.svg'
import RadioButtonUncheckedIcon from '@/assets/icons/radio-button-unchecked.svg'
import ButtonContactUs from '@/components/shared/common/ButtonContactUs.vue'

const props = defineProps({
  phoneCards: {
    type: Object as PropType<CarouselMobileCard[]>,
    required: true,
  },
  imageContainerClass: {
    type: String,
    default: 'w-[284px] h-[582px]',
  },
  contentContainerClass: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  showContactUsButton: {
    type: Boolean,
    default: false,
  },
})

type InteractivePhoneCard = CarouselMobileCard & { isActive: boolean }

const cards = ref<InteractivePhoneCard[]>(
  props.phoneCards.map((card, i) => ({ ...card, isActive: i === 0 })),
)

const selectedCard = ref<CarouselMobileCard | null>(null)

watch(selectedCard, () => {
  if (selectedCard.value) {
    toggleRadioButton()
  }
})

const toggleRadioButton = () => {
  cards.value.forEach((card) => {
    if (card.title === selectedCard.value.title) {
      card.isActive = true
      return
    }
    card.isActive = false
  })
}
</script>

<template>
  <div class="bg-white rounded-xl p-6 pr-3">
    <h2 class="text-h3 mb-6">{{ title }}</h2>
    <div
      class="flex gap-x-7 justify-between xl:pr-20"
      :class="contentContainerClass"
    >
      <div>
        <div
          v-for="card in cards"
          :key="card.title"
          class="text-[#a3a5ae] hover:text-black cursor-pointer max-w-[806px] border-l-[3px] border-[#a3a5ae] pl-[21px] py-4.5 rounded-r-lg transition-all"
          :class="{
            'shadow-[0_1px_10px_0_#c1c5cf] border-p-500 bg-card text-black':
              card.isActive,
          }"
          @click="selectedCard = card"
        >
          <h3 class="text-h4 mb-2">{{ card.title }}</h3>
          <p class="text-lg mb-3.5">{{ card.description }}</p>
          <div class="relative w-6 h-6">
            <RadioButtonCheckedIcon
              class="absolute w-6 h-6 transition-opacity text-p-500"
              :class="card.isActive ? 'opacity-100' : 'opacity-0'"
            />
            <RadioButtonUncheckedIcon
              class="absolute w-6 h-6 transition-opacity"
              :class="card.isActive ? 'opacity-0' : 'opacity-100'"
            />
          </div>
        </div>
        <ButtonContactUs
          v-if="showContactUsButton"
          class="mt-6"
          text="Find Out More"
        />
      </div>
      <div class="shrink-0">
        <div class="relative" :class="imageContainerClass">
          <img
            v-for="card in cards"
            :key="card.title + '-image'"
            class="absolute opacity-0 w-full h-full"
            :class="{
              'opacity-100': card.isActive,
            }"
            :src="card.src"
            :alt="card.title + ' banner'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
